import * as React from "react";
import { HeadFC, Link } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const AUTOURS = [
  { name: "井上公輔", slug: "kosuke-inoue" },
  { name: "中川孟", slug: "hajime-nakagawa" },
  { name: "松尾俊弥", slug: "toshiya-matsuo" },
];

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">著者一覧</h1>
      <ul>
        {AUTOURS.map((author) => {
          return (
            <li key={author.name}>
              <Link to={`/author/${author.slug}`}>{author.name}</Link>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <Seo />;
